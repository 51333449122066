import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./assets/css/style.css"; //全局样式
import "./utils/filter"; //全局过滤器
import "./utils/directive"; //全局自定义指令
import "./utils/flexible"; // 屏幕适配
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueQuillEditor from "vue-quill-editor"; // 富文本编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "wowjs/css/libs/animate.css"; //wow.js、animate
import scroll from "vue-seamless-scroll"; // scroll
// 引入echarts
// import echarts from "echarts";
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
Vue.prototype.$imgOnlineUrl = "https://data.darsing.net/images/pc";
Vue.prototype.$cesiumKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNGIzMTQ1My01OGFkLTQwODMtYjdlZC1jNTFmZjI3NDRkYjUiLCJpZCI6MTI4Nzg5LCJpYXQiOjE2Nzg4NTc5NDR9.kwIKR4Fd9_R9JoeTWTSseOB9vCWUTt1IrZ644r5JcZY"; // cesium地图key

Vue.prototype.$solscanKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0NWRiZjk2YzI2MWQxMjA1NWJiYzIyYSIsImVtYWlsIjoieW15bnlnQDE2My5jb20iLCJhY3Rpb24iOiJyZXNldFB3ZCIsImlhdCI6MTY5OTI1ODczOSwiZXhwIjoxNjk5MjY1OTM5fQ.tq_zVtiUDjxdcRUgPAoESbZA0si3bVMLM1hCzkgrWDc";

Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(ElementUI);
Vue.use(scroll);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
