import Vue from "vue";
import VueRouter from "vue-router";
// import { Notification } from "element-ui";

Vue.use(VueRouter);
import common from "./common";
const routes = [...common];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    sessionStorage.clear();
    return next();
  }
  let userToken = localStorage.getItem("darsingToken") || "";
  let exp = +localStorage.getItem("exp") || 0;
  let nowTime = +new Date();
  if (!userToken || exp < nowTime) {
    localStorage.removeItem("userInfoObj");
    localStorage.removeItem("farmInfo");
    localStorage.removeItem("farmId");
    localStorage.removeItem("darsingToken");
    sessionStorage.clear();
    return next("/login");
  }
  next();
});
export default router;
