export default [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
    meta: {
      isLogin: true,
    },
  },
  // 眼
  {
    path: "/drying",
    name: "drying",
    component: () => import("@/views/drying/index.vue"),
    children: [
      {
        path: "/drying/drying",
        name: "dryingInfo",
        component: () => import("@/views/drying/drying.vue"),
      },
      {
        path: "/drying/list",
        name: "dryingList",
        component: () => import("@/views/drying/list.vue"),
      },
      {
        path: "/drying/plan",
        name: "dryingPlanList",
        component: () => import("@/views/drying/dryingPlanList.vue"),
      },
    ],
  },
  {
    path: "/processList",
    name: "processList",
    component: () => import("@/views/process/index.vue"),
    children: [
      {
        path: "/process/plan",
        name: "processPlan",
        component: () => import("@/views/process/plan.vue"),
      },
      {
        path: "/process/statistics",
        name: "processStatistics",
        component: () => import("@/views/process/statistics.vue"),
      },
      {
        path: "/process/list",
        name: "processList",
        component: () => import("@/views/process/list.vue"),
      },
    ],
  },
  {
    path: "/market",
    name: "market",
    component: () => import("@/views/market.vue"),
    meta: {},
  },
  {
    path: "/service",
    name: "service",
    component: () => import("@/views/service.vue"),
  },
  {
    path: "/uav",
    name: "uav",
    component: () => import("@/views/uav.vue"),
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("@/views/shop.vue"),
  },
  {
    path: "/storage",
    name: "storage",
    component: () => import("@/views/storage.vue"),
  },

  {
    path: "/brand",
    name: "brand",
    component: () => import("@/views/brand.vue"),
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/traceability",
    name: "traceability",
    redirect: "/traceability/lot",
    component: () => import("@/views/traceability.vue"),
    children: [
      {
        path: "/traceability/lot",
        name: "lot",
        component: () => import("@/views/traceability/lot.vue"),
        meta: {
          title: "lot",
        },
      },
      {
        path: "/traceability/barcode",
        name: "barCode",
        component: () => import("@/views/traceability/barCode.vue"),
        meta: {
          title: "barCode",
        },
      },
      {
        path: "/traceability/info",
        name: "info",
        component: () => import("@/views/traceability/info.vue"),
        meta: {
          title: "info",
        },
      },
      {
        path: "/traceability/infoinput",
        name: "infoInput",
        component: () => import("@/views/traceability/infoInput.vue"),
        meta: {
          title: "infoinput",
        },
      },
      {
        path: "/traceability/stat",
        name: "stat",
        component: () => import("@/views/traceability/stat.vue"),
        meta: {
          title: "stat",
        },
      },
      {
        path: "/traceability/uplink",
        name: "upLink",
        component: () => import("@/views/traceability/upLink.vue"),
        meta: {
          title: "uplink",
        },
      },
    ],
  },
  {
    path: "/land",
    name: "land",
    component: () => import("@/views/land/land.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/vr",
    name: "vr",
    component: () => import("@/views/vr.vue"),
  },
  {
    path: "/farmadmin",
    name: "farmAdmin",
    component: () => import("@/views/farmAdmin.vue"),
  },
  {
    path: "/linkman",
    name: "linkman",
    component: () => import("@/views/linkman.vue"),
  },
  {
    path: "/configure",
    name: "configure",
    redirect: "/configure/farm",
    // redirect: "/configure/txFarm",
    component: () => import("@/views/configure/index.vue"),
    children: [
      {
        path: "/configure/farm",
        name: "farm",
        component: () => import("@/views/configure/farm.vue"),
        meta: {
          title: "farm",
        },
      },
      // {
      //   path: "/configure/txFarm",
      //   name: "txFarm",
      //   component: () => import("@/views/configure/txFarm.vue"),
      //   meta: {
      //     title: "txFarm",
      //   },
      // },
      {
        path: "/configure/cropType",
        name: "cropType",
        component: () => import("@/views/configure/cropType.vue"),
        meta: {
          title: "cropType",
        },
      },
      {
        path: "/configure/assetType",
        name: "assetType",
        component: () => import("@/views/configure/assetType.vue"),
        meta: {
          title: "assetType",
        },
      },
      {
        path: "/configure/processTemplate",
        name: "processTemplate",
        component: () => import("@/views/configure/processTemplate.vue"),
        meta: {
          title: "processTemplate",
        },
      },
      {
        path: "/configure/process",
        name: "process",
        component: () => import("@/views/configure/process.vue"),
        meta: {
          title: "process",
        },
      },
      {
        path: "/configure/datum",
        name: "datum",
        component: () => import("@/views/configure/datum.vue"),
        meta: {
          title: "datum",
        },
      },
      {
        path: "/configure/password",
        name: "password",
        component: () => import("@/views/configure/password.vue"),
        meta: {
          title: "password",
        },
      },
      {
        path: "/configure/log",
        name: "log",
        component: () => import("@/views/configure/log.vue"),
        meta: {
          title: "log",
        },
      },
      {
        path: "/configure/town",
        name: "town",
        component: () => import("@/views/configure/town.vue"),
        meta: {
          title: "town",
        },
      },
      {
        path: "/configure/statement",
        name: "statement",
        component: () => import("@/views/configure/statement.vue"),
        meta: {
          title: "statement",
        },
      },
    ],
  },
  {
    path: "/alter",
    name: "alter",
    component: () => import("@/views/alter.vue"),
  },
  // {
  //   path: "/alterTxMap",
  //   name: "alterTxMap",
  //   component: () => import("@/views/alterTxMap.vue"),
  // },
  {
    path: "/means",
    name: "means",
    component: () => import("@/views/means.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/crops",
    name: "crops",
    component: () => import("@/views/crops.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/personnel",
    name: "personnel",
    component: () => import("@/views/personnel.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/news.vue"),
  },

  {
    path: "/know",
    name: "knowledge",
    component: () => import("@/views/knowledge.vue"),
  },
  {
    path: "/dialogue",
    name: "dialogue",
    component: () => import("@/views/dialogue/index.vue"),
  },

  {
    path: "/task",
    name: "task",
    component: () => import("@/views/task.vue"),
  },
  {
    path: "/area",
    name: "area",
    component: () => import("@/views/drawnArea.vue"),
  },
  // 设备
  {
    path: "/equipment",
    name: "equipment",
    // redirect: "/equipment/gather",
    redirect: "/equipment/meteorological",
    component: () => import("@/views/equipment.vue"),
    children: [
      {
        path: "/equipment/meteorological",
        name: "meteorological",
        component: () => import("@/views/equipment/meteorological.vue"),
      },
      {
        path: "/equipment/ai",
        name: "equipmentAi",
        component: () => import("@/views/equipment/ai.vue"),
      },
      {
        path: "/equipment/chemical",
        name: "chemical",
        component: () => import("@/views/equipment/chemical.vue"),
      },

      {
        path: "/equipment/gather",
        name: "gather",
        component: () => import("@/views/equipment/gather.vue"),
      },

      // 假的 后面删掉
      {
        path: "/equipment/grow",
        name: "equipmentgrow",
        component: () => import("@/views/equipment/grow.vue"),
      },
      {
        path: "/equipment/water",
        name: "equipmentwater",
        component: () => import("@/views/equipment/water.vue"),
      },
      {
        path: "/equipment/gate",
        name: "equipmentgate",
        component: () => import("@/views/equipment/gate.vue"),
      },
      {
        path: "/equipment/insect",
        name: "equipmentinsect",
        component: () => import("@/views/equipment/insect.vue"),
      },
      {
        path: "/equipment/machine",
        name: "equipmentmachine",
        component: () => import("@/views/equipment/machine.vue"),
      },
    ],
  },
  // {
  //   path: "/records",
  //   name: "records",
  //   component: () => import("@/views/records/index.vue"),
  // },
  {
    path: "/grow",
    name: "grow",
    redirect: "/grow/video",
    component: () => import("@/views/grow/index.vue"),
    children: [
      {
        path: "/grow/video",
        name: "video",
        component: () => import("@/views/grow/video.vue"),
      },
      {
        path: "/grow/records",
        name: "records",
        component: () => import("@/views/grow/records.vue"),
      },
    ],
  },
  // 农场秀
  {
    path: "/farmShow/index",
    name: "farmIndex",
    redirect: "/farmShow/show",
    component: () => import("@/views/farmShow/index.vue"),
    children: [
      {
        path: "/farmShow/show",
        name: "show",
        redirect: "/farmShow/show/list",
        component: () => import("@/views/farmShow/show.vue"),
        children: [
          {
            path: "/farmShow/show/list",
            name: "showList",
            component: () => import("@/views/farmShow/list.vue"),
          },
          {
            path: "/farmShow/show/edit",
            name: "showEdit",
            component: () => import("@/views/farmShow/edit.vue"),
          },
        ],
      },
    ],
  },
  // 错误页面

  {
    path: "/exception/403",
    name: "403",
    component: () => import("@/views/exception/403.vue"),
    meta: {
      title: "403",
      isLogin: true,
    },
  },
  {
    path: "/exception/404",
    name: "404",
    component: () => import("@/views/exception/404.vue"),
    meta: {
      title: "404",
      isLogin: true,
    },
  },
  {
    path: "/exception/500",
    name: "500",
    component: () => import("@/views/exception/500.vue"),
    meta: {
      title: "500",
      isLogin: true,
    },
  },
  {
    path: "/exception/600",
    name: "600",
    component: () => import("@/views/exception/routeError.vue"),
    meta: {
      title: "路由配置错误",
      isLogin: true,
    },
  },
  {
    path: "/bigWeb",
    name: "bigWeb",
    component: () => import("@/views/bigWeb.vue"),
    meta: {
      title: "图表",
      isLogin: true,
    },
  },
  {
    path: "/noteBook",
    name: "noteBook",
    component: () => import("@/views/noteBook.vue"),
    meta: {
      title: "产品手册",
    },
  },
  // {
  //   path: "/test1",
  //   name: "test1",
  //   component: () => import("@/views/test1.vue"),
  //   meta: {
  //     title: "",
  //   },
  // },
  // {
  //   path: "/test2",
  //   name: "test2",
  //   component: () => import("@/views/test2.vue"),
  //   meta: {
  //     title: "",
  //   },
  // },
  // {
  //   path: "/test3",
  //   name: "test3",
  //   component: () => import("@/views/test3.vue"),
  //   meta: {
  //     title: "",
  //   },
  // },
  // {
  //   path: "/grainStorage",
  //   name: "grainStorage",
  //   component: () => import("@/views/grainStorage/index.vue"),
  //   meta: {
  //     title: "成品粮仓储",
  //   },
  // },
  // 眼
  {
    path: "/grainStorage",
    name: "grainStorage",
    redirect: "/grainStorage/camera",
    component: () => import("@/views/grainStorage/index.vue"),
    children: [
      {
        path: "/grainStorage/camera",
        name: "grainCamera",
        component: () => import("@/views/grainStorage/camera.vue"),
      },
      {
        path: "/grainStorage/list",
        name: "grainStorageList",
        component: () => import("@/views/grainStorage/list.vue"),
      },
      {
        path: "/grainStorage/drying",
        name: "grainStorageDrying",
        component: () => import("@/views/grainStorage/drying.vue"),
      },
    ],
  },
];
