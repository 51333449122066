const state = {
  // 图片上传
  uploadImgs: "https://resource.darsing.net/api/v1/img/",
  // 文件/视频上传
  uploadFiles: "https://resource.darsing.net/api/v1/file",
  navShow: true,
};
const mutations = {
  SET_NAV_SHOW: (state, { navShow }) => {
    state.navShow = navShow;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
