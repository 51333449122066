const getDefaultState = () => {
  return {
    token: localStorage.getItem("darsingToken") || "",
    exp: localStorage.getItem("exp") || "",
    farmID: +localStorage.getItem("farmId") || "",
    equipmentName: sessionStorage.getItem("equipmentName") || "",

    userInfo: JSON.parse(localStorage.getItem("userInfoObj")) || {},
    msgCount: null,
    nav: 0,
    taskCount: null,
    farmInfo: JSON.parse(localStorage.getItem("farmInfo")) || {},
    farmShow: {},
  };
};
const state = getDefaultState();

const mutations = {
  SET_NAV: (state, { nav }) => {
    state.nav = nav;
  },
  SET_EQUIPMENT_Name: (state, { icon }) => {
    state.equipmentName = icon;
  },
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },

  SET_MSG_COUNT: (state, { msgCount }) => {
    state.msgCount = msgCount;
  },
  SET_FARM_INFO: (state, { farmInfo }) => {
    localStorage.setItem("farmInfo", JSON.stringify(farmInfo));
    state.farmInfo = farmInfo;
  },
  SET_FARM_Id: (state, { farmId }) => {
    localStorage.setItem("farmId", farmId);
    state.farmID = farmId;
  },
  SET_FARM_SHOW: (state, { farmShow }) => {
    state.farmShow = farmShow;
  },
  SET_TASK_COUNT: (state, { taskCount }) => {
    state.taskCount = taskCount <= 0 ? 0 : taskCount;
  },

  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
